@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Saira+Condensed:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0
}

.app {
  background-color: #fdfdfd
}

button,
input,
select,
textarea {
  font: inherit;
  margin: 0
}


.blueBg {
  background-color: #e8eff7
}

#root {
  background-color: var(--bg);
  margin: 0 auto;
  text-align: center;
  width: 100%
}

body {
  background-color: #d1cece;
  font-family: Tomorrow, sans-serif
}

a {
  text-decoration: none
}

h4 {
  font-size: 21px;
  font-weight: 600
}

.textAlignCenter {
  text-align: center !important
}

@media(max-width:768px) {

  .default,
  .secondary {
    padding: 15px
  }

  .disclaimer {
    padding: 20px !important
  }
}

.nb4Ini {
  background-color: #021d40
}

.HzrWZ3 {
  display: none
}

.Q6wh4q {
  align-items: center;
  column-gap: 31px;
  display: flex;
  font-weight: 400;
  justify-content: space-between;
  max-width: 1460px;
  padding: 20px 120px
}

.Q6wh4q .MYE7uP,
.Q6wh4q a {
  background-color: inherit;
  background: none;
  border: none;
  color: #fdfdfd;
  cursor: pointer;
  font-size: 20px;
  font-weight: 400;
  margin: 0;
  outline: none;
  padding: 0;
  text-decoration: none
}

.FzcRCE {
  cursor: pointer;
  display: none;
  flex-direction: column
}

.FzcRCE .HPUQEb {
  background-color: #fff;
  height: 4px;
  margin: 3px 0;
  transition: all .3s ease;
  width: 25px
}

.QKJB4a {
  color: #fff;
  display: flex;
  gap: 50px
}

.ofbuge {
  display: inline-block;
  position: relative
}

.ofbuge .j4JooY {
  align-items: center;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  text-decoration: none
}

.ofbuge .j4JooY:hover {
  color: #bf215b
}

.ofbuge .LJpIIK {
  font-size: 12px;
  margin-left: 5px
}

.ofbuge .JFwsH7 {
  background-color: #021d40;
  box-shadow: 0 8px 16px rgba(0, 0, 0, .2);
  display: none;
  left: 0;
  min-width: 160px;
  position: absolute;
  top: 100%;
  z-index: 1
}

.ofbuge .JFwsH7 a {
  color: #fdfdfd;
  display: block;
  padding: 12px 16px;
  text-decoration: none
}

.ofbuge .JFwsH7 a:hover {
  color: #bf215b
}

.ofbuge:hover .JFwsH7 {
  display: block
}

.BXg5DB {
  background-color: #021d40;
  box-shadow: 0 0 10px rgba(0, 0, 0, .3);
  display: none;
  flex-direction: column;
  padding: 20px;
  position: absolute;
  right: 0;
  top: 97px;
  width: 100%;
  z-index: 10
}

.BXg5DB .MYE7uP,
.BXg5DB a {
  font-size: 18px;
  margin: 10px 0
}

@media(max-width:1450px) {
  .Q6wh4q {
    padding: 24px 90px !important
  }
}

@media(max-width:1300px) {
  .Q6wh4q {
    padding: 24px 80px !important
  }
}

@media(max-width:1050px) {
  .Q6wh4q {
    padding: 24px 70px !important
  }
}

@media(max-width:768px) {
  .HzrWZ3 {
    display: block
  }

  .QKJB4a {
    display: none
  }

  .Q6wh4q {
    justify-content: space-between;
    padding: 20px 30px !important
  }

  .BXg5DB,
  .FzcRCE {
    display: flex
  }
}

.nEKDrv {
  align-items: center;
  background-color: #021d40;
  column-gap: 25px;
  display: flex;
  padding: 20px 120px
}

.nEKDrv a {
  color: #fdfdfd;
  cursor: pointer;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 400;
  margin-right: 20px;
  text-decoration: none
}

.bNge3l {
  max-width: 406px
}

.Lv0l7N {
  font-weight: 600;
  margin-top: 20px
}

._f2GJv,
.j07ZS_ {
  display: flex;
  flex-direction: column;
  row-gap: 15px
}

.EqOSBd {
  column-gap: 25px;
  display: flex
}

@media(max-width:1450px) {
  .nEKDrv {
    padding: 24px 90px !important
  }
}

@media(max-width:1300px) {
  .nEKDrv {
    padding: 24px 80px !important
  }
}

@media(max-width:1050px) {
  .nEKDrv {
    padding: 24px 70px !important
  }
}

@media(max-width:768px) {
  ._f2GJv {
    display: none
  }

  .nEKDrv {
    padding: 40px 20px !important;
    row-gap: 15px
  }

  .nEKDrv,
  .nb6S9A {
    flex-direction: column
  }

  .nb6S9A {
    display: flex;
    text-align: left
  }

  .j07ZS_ {
    flex-direction: row
  }
}

.reYbGw.pSnrE0 {
  font-size: 54px;
  font-weight: 700
}

.reYbGw.Kz2XEh {
  font-size: 38px;
  font-weight: 600
}

.reYbGw.N49ECd {
  font-size: 26px;
  font-weight: 600
}

.qUtLBp {
  color: #021d40
}

@media(max-width:1050px) {
  .reYbGw.pSnrE0 {
    font-size: 42px
  }

  .reYbGw.Kz2XEh {
    font-size: 28px
  }

  .reYbGw.N49ECd {
    font-size: 22px
  }
}

@media(max-width:768px) {
  .reYbGw.pSnrE0 {
    font-size: 36px
  }

  .reYbGw.Kz2XEh {
    font-size: 24px
  }

  .reYbGw.N49ECd {
    font-size: 20px
  }
}

.TMB4Tn {
  align-items: center;
  background: #bf215b;
  border: none;
  border-radius: 60px;
  color: #fdfdfd;
  cursor: pointer;
  display: flex;
  font-size: 20px;
  font-weight: 500;
  margin-top: 20px;
  padding: 14px 36px;
  position: relative;
  width: fit-content;
}

.TMB4Tn img {
  display: none
}

.TMB4Tn.Lc8pBB {
  background: inherit;
  color: #fdfdfd;
  column-gap: 10px;
  margin-left: auto;
  margin-right: 0;
  padding-right: 0
}

.TMB4Tn.Lc8pBB img {
  display: block
}

@media(max-width:768px) {
  .TMB4Tn {
    font-size: 14px;
    height: 40px;
    justify-content: center;
    text-align: center;
    width: 100%
  }

  .TMB4Tn.Lc8pBB {
    justify-content: flex-end
  }
}

.aEW9qr {
  margin-top: 20px
}

.aEW9qr.q6oMlS {
  font-size: 24px;
  font-weight: 400
}

.aEW9qr.oC4CPl {
  font-size: 22px;
  font-weight: 400
}

.aEW9qr.pBfnFb {
  font-size: 18px;
  font-weight: 400
}

@media(max-width:1050px) {
  .aEW9qr {
    margin-top: 24px
  }

  .aEW9qr.q6oMlS {
    font-size: 18px
  }

  .aEW9qr.oC4CPl,
  .aEW9qr.pBfnFb {
    font-size: 16px
  }
}

@media(max-width:768px) {
  .aEW9qr {
    margin-top: 16px
  }

  .aEW9qr.q6oMlS {
    font-size: 18px
  }

  .aEW9qr.oC4CPl {
    font-size: 16px
  }

  .aEW9qr.pBfnFb {
    font-size: 14px
  }
}

.PQuf5l {
  max-width: 600px;
  text-align: left
}

@media(max-width:1200px) {
  .PQuf5l {
    max-width: 437px
  }
}

@media(max-width:1050px) {
  .PQuf5l {
    max-width: 100%
  }
}

.huSqzT {
  max-width: 480px;
  text-align: left
}

.Ds3eh4 {
  margin-top: 15px
}

.Ds3eh4 p {
  margin-top: 6px
}

.d2GFdA .amWgnE {
  margin-bottom: 20px
}

.d2GFdA.Voaooq {
  text-align: center
}

.auvXeh {
  background-repeat: no-repeat;
  background-size: cover;
  color: #fdfdfd;
  max-width: 375px;
  padding: 300px 16px 16px;
  text-align: left
}

.mIIGm3 {
  margin: 0 auto;
  max-width: 1000px
}

@media(max-width:1050px) {
  .auvXeh {
    max-width: 100%
  }
}

@media(max-width:768px) {
  .auvXeh {
    background-size: cover;
    padding: 200px 25px 16px
  }
}

.il1bRU {
  display: block
}

.il1bRU.JajTCy {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto)
}

.il1bRU.X_J4cm {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, auto);
  margin: 0 auto
}

@media(max-width:1050px) {
  .il1bRU.X_J4cm {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, auto)
  }
}

@media(max-width:768px) {

  .il1bRU.JajTCy,
  .il1bRU.X_J4cm {
    gap: 12px;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, auto)
  }
}

.NnEaZe {
  margin: 0 auto;
  max-width: 1460px;
  padding: 50px 120px
}

.NnEaZe.GrLQXE {
  align-items: center;
  column-gap: 60px;
  display: flex;
  justify-content: space-between
}

.NnEaZe.yMUMlr {
  flex-direction: row-reverse
}

.NnEaZe.TvsuHC {
  flex-direction: column
}

.NnEaZe.TvsuHC.yMUMlr {
  flex-direction: column-reverse
}

.NnEaZe.hDxjpo {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 2px -2px 45.6px 0 #d0d1d7;
  padding: 0
}

.NnEaZe.gRoy15 {
  padding-left: 0;
  padding-right: 0
}

.NnEaZe.qW8NLg {
  align-items: center
}

.NnEaZe.vZjddF {
  align-items: start
}

@media(max-width:1450px) {
  .NnEaZe {
    padding: 45px 110px
  }
}

@media(max-width:1300px) {
  .NnEaZe {
    padding: 40px 100px
  }
}

@media(max-width:1050px) {
  .NnEaZe {
    padding: 35px 90px
  }

  .NnEaZe.a686tO {
    flex-direction: column-reverse;
    row-gap: 40px
  }
}

@media(max-width:768px) {
  .NnEaZe {
    flex-direction: column;
    margin: 0 auto;
    max-width: 375px;
    padding: 20px 30px;
    row-gap: 20px
  }

  .NnEaZe img {
    max-width: 320px
  }

  .NnEaZe.yMUMlr {
    flex-direction: column-reverse;
    row-gap: 20px
  }

  .NnEaZe.TvsuHC.yMUMlr {
    flex-direction: column
  }
}

.YsJlHh {
  background-size: cover;
  color: #fff;
  text-align: left
}

.YsJlHh .meuLqY {
  padding-bottom: 116px;
  padding-top: 116px
}

.YsJlHh .C16vnj,
.YsJlHh .tqbcOW {
  max-width: 622px
}

@media(max-width:768px) {
  .YsJlHh .meuLqY {
    padding-bottom: 46px;
    padding-top: 46px
  }
}

.Nwa0WP {
  display: grid;
  gap: 16px
}

.Nwa0WP.mWdxIz {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr)
}

.Nwa0WP.u9xax_ {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr)
}

@media(max-width:768px) {
  .Nwa0WP {
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    gap: 8px
  }

  .Nwa0WP img {
    flex: 0 0 auto;
    scroll-snap-align: start
  }
}

.Wo0vsM {
  max-width: 333px;
  padding: 16px
}

.Wo0vsM .LtGra_ {
  margin-bottom: 20px
}

.wSoGeH {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  row-gap: 20px;
  text-align: left
}

.wSoGeH .UnIWe0 {
  transition: transform .3s ease
}

.wSoGeH .jJvKsg {
  border: 2px solid #171717;
  border-radius: 20px;
  padding: 20px
}

.wSoGeH .DrXS8w {
  display: flex;
  justify-content: space-between
}

.wSoGeH .LXZvWQ {
  transform: rotate(180deg)
}

.oa7Sy6 {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 2px -2px 45.6px 0 #d0d1d7;
  display: flex;
  justify-content: space-around;
  margin-top: 80px
}

.oa7Sy6 .xa7nK5 {
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 700;
  padding: 10px 20px
}

.oa7Sy6 .xa7nK5.Qt3gj_ {
  color: var(--primary)
}

@media(max-width:768px) {
  .oa7Sy6 {
    display: grid;
    margin-top: 30px
  }

  .btnmax {
    padding: 5px;
    width: max-content;
    font-size: 12px;
  }
}

.ArXblP {
  align-items: center;
  border-bottom: 1px solid #bf215b;
  column-gap: 12px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 14px;
  padding-top: 14px
}

.ArXblP p {
  margin-top: 0
}

.BkOmPW {
  display: flex;
  flex-direction: column
}

.BkOmPW .DQilz_ {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 2px -2px 45.6px 0 #d0d1d7;
  display: flex;
  justify-content: space-between;
  margin-top: 20px
}

.BkOmPW .DQilz_ .tCPvhv {
  display: flex;
  margin-top: 25px;
  text-align: start
}

.BkOmPW .DQilz_ .tCPvhv p {
  margin-top: 0
}

.BkOmPW .DQilz_ .At6IER {
  margin-top: 48px
}

.BkOmPW .DQilz_ .hVGhPA {
  flex: 1;
  max-width: 420px;
  padding: 48px;
  text-align: left
}

.BkOmPW .DQilz_ .hVGhPA .koNh0O {
  margin-top: 20px
}

.BkOmPW .DQilz_ .hVGhPA .koNh0O>div {
  margin-bottom: 10px
}

.BkOmPW .DQilz_ .z6rmaI {
  flex: 1
}

.BkOmPW .DQilz_ .z6rmaI img {
  max-width: 760px;
  width: 100%
}

@media(max-width:768px) {
  .BkOmPW .DQilz_ .hVGhPA {
    padding: 20px
  }

  .BkOmPW .DQilz_ .At6IER {
    margin-top: 20px
  }

  .BkOmPW .DQilz_ .z6rmaI {
    display: none
  }
}

.RZnBVh {
  column-gap: 12px;
  display: flex;
  justify-content: space-between
}

.kNbiH_ img {
  max-width: 100%
}

.X041l5 {
  display: grid;
  gap: 40px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  margin-top: 40px;
  text-align: left
}

.I6Jw6z div div {
  padding: 20px
}

.I6Jw6z.Ez_RVI {
  column-gap: 30px;
  display: flex;
  justify-content: space-between;
  margin-top: 60px
}

.dR9UZ8 {
  background-color: var(--secondary)
}

@media(max-width:1200px) {
  .VBAPG6 {
    height: min-content;
    max-width: 50%
  }
}

@media(max-width:768px) {
  .VBAPG6 {
    max-width: 100%
  }

  .X041l5 {
    display: flex;
    flex-direction: column
  }

  .X041l5 div {
    align-items: start
  }

  .I6Jw6z.Ez_RVI {
    flex-direction: column;
    margin-top: 25px;
    row-gap: 20px
  }

  .I6Jw6z div div {
    padding: 10px
  }
}

.V7NUU2 {
  align-items: center;
  background-color: #1669c9;
  flex-direction: column;
  gap: 21px;
  justify-content: center;
  padding: 48px
}

.I5x6cJ {
  padding-bottom: 80px
}

.gx_82z {
  font-size: 60px;
  font-style: normal;
  font-weight: 700
}

.oRoPgI {
  font-size: 20px
}

.kYbp1g,
.oRoPgI {
  font-style: normal;
  font-weight: 400
}

.kYbp1g {
  color: inherit;
  display: grid;
  font-family: Exo\ 2, sans-serif;
  font-size: 18px;
  line-height: 150%;
  opacity: 0;
  text-align: left;
  width: 300px
}

.GyRCyz {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px
}

.VCbOYc {
  color: crimson;
  font-size: 15px;
  margin: 10px auto 0;
  max-width: 250px
}

.hGyviD {
  margin: 40px auto 0
}

.hA1zbd {
  color: #161515;
  height: 100px !important;
  margin-top: 12px;
  max-width: 1180px;
  resize: none;
  width: 100% !important
}

.KBq8g4,
.hA1zbd {
  background: var(--white, #fefefd);
  border: 2px solid #5b5969;
  border-radius: 10px;
  padding: 16px
}

.KBq8g4 {
  color: #8f8f8f;
  font-family: Exo\ 2, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 350px
}

.PbMwDD {
  display: flex;
  flex-direction: column
}

.vewoo8 {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, auto);
  margin-top: 40px
}

.vewoo8> :nth-child(7) {
  grid-column: 1/-1
}

.jCspiy {
  background: var(--white, #fefefd);
  border: 2px solid #5b5969;
  border-radius: 10px;
  color: #161515;
  margin-top: 12px;
  padding: 16px
}

.OxaYmk {
  display: flex;
  flex-direction: column
}

.uuApXI {
  width: 250px
}

.bis9qZ {
  width: 100%
}

.YrbIZW {
  display: flex;
  justify-content: right
}

@media(max-width:1258px) {
  .hGyviD {
    margin: 20px auto 0;
    max-width: 1000px
  }

  .vewoo8 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto)
  }
}

@media(max-width:1058px) {
  .GyRCyz {
    flex-direction: column
  }
}

@media(max-width:768px) {
  .KBq8g4 {
    width: 100%
  }

  .YrbIZW {
    display: flex;
    justify-content: center;
    margin-top: 10px
  }

  .GyRCyz {
    flex-direction: column
  }

  .PbMwDD {
    max-width: 340px
  }

  .I5x6cJ {
    padding: 0 20px 80px
  }

  .kYbp1g {
    margin-top: 10px;
    width: 100%
  }
}

.lQ5EGw {
  background-color: #e8eff7;
  border-bottom: 1px solid #fff;
  border-radius: 20px;
  margin: 80px auto
}

.lQ5EGw,
.lQ5EGw div {
  color: #171717;
  max-width: 1180px;
  text-align: center
}

.lQ5EGw div {
  margin: 0 auto;
  padding: 40px
}

.lQ5EGw div div {
  color: #171717;
  padding-top: 0
}

.lQ5EGw button {
  margin: 0 auto
}

.GcXGI9 {
  min-height: 80vh
}

.GcXGI9 h1,
.GcXGI9 p {
  text-align: center
}

.NAaI6H {
  font-size: 24px;
  font-weight: 700
}

.O0jdnO {
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  max-width: 1300px;
  padding: 20px;
  row-gap: 20px;
  text-align: left
}

.HAokFn {
  font-size: 24px;
  font-weight: 700
}

.DtnvuZ {
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  max-width: 1300px;
  padding: 20px;
  row-gap: 20px;
  text-align: left
}

.AsAfLw {
  font-size: 24px;
  font-weight: 700
}

.fahrf9 {
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  max-width: 1300px;
  padding: 20px;
  row-gap: 20px;
  text-align: left
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/*==BASIC-SETUP==*/
body {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  background: #ffffff;
}

.container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;

}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}



a,
a:hover,
a:active {
  text-decoration: none;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label {
  margin-bottom: 0;
}

/* scroll-bar */
.scrolltotop {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background: #DE081C;
  text-align: center;
  padding-top: 8px;
  font-size: 20px;
  color: #ffffff;
  position: fixed;
  right: 8px;
  bottom: 10px;
  display: none;
  transition: 0.2s all ease;
  -webkit-transition: 0.2s all ease;
  -moz-transition: 0.2s all ease;
}

.scrolltotop:hover {
  color: #fff;
}

/*==THIS IS START==*/


/* MOBIL-MENU-START*/
.sidebar-menu {
  backdrop-filter: blur(15px);
  width: 250px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: -250px;
  overflow-y: auto;
  transition: 0.6s ease;
  transition-property: left;
  background: #000000;
  z-index: 20;
  display: none;
}

.menu-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidebar-menu::-webkit-scrollbar {
  width: 0px;
}

.sidebar-menu.active {
  left: 0;
}

.sidebar-menu .menu-wrap {
  width: 100%;
  margin-top: 10px;
}

.sidebar-menu .menu-wrap .menu-item {
  position: relative;
  cursor: pointer;
}

.sidebar-menu .menu-wrap .menu-item a {
  display: block;
  padding: 10px 9px;
  font-family: "Saira Condensed", sans-serif;
  font-weight: 500;
  color: #ffffff;
  font-size: 20px;
  text-transform: uppercase;
  position: relative;
  transition: 0.2s;
}

.sidebar-menu .menu-wrap .menu-item a:hover {
  opacity: 0.8;
}

.sidebar-menu .menu-header {
  position: relative;
  background: #000000;
  padding: 10px 8px;
}

.close-icon {
  width: 25px;
  height: 25px;
  display: block;
  position: relative;
  cursor: pointer;
}

.close-icon::before,
.close-icon::after {
  content: '';
  background: red;
  position: absolute;
  height: 100%;
  width: 4px;
  border-radius: 3px;
}

.close-icon::before {
  transform: rotate(45deg);
}

.close-icon::after {
  transform: rotate(-45deg);
}

.menu-btn {
  width: 40px;
  cursor: pointer;
  margin-left: auto;
  display: none;
}

.menu-btn span {
  display: block;
  width: 100%;
  height: 4px;
  background: red;
  border-radius: 10px;
  margin: 5px 0 0 0;
}

.mobil-brand {
  width: 100%;
  padding-left: 25px;
}

.mobil-brand img {
  max-width: 90px;
}

/* MOBIL-MENU-END*/

.header-area {
  background-color: #000000;
}

.header_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.logo img {
  width: 120px;
}

.header_inner .nav_items ul {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.nav_items ul li {
  padding-left: 50px;
}

.nav_items ul li a {
  font-family: "Saira Condensed", sans-serif;
  font-weight: 500;
  color: #ffffff;
  font-size: 22px;
  text-transform: uppercase;
  position: relative;
}

.nav_items ul li a::after {
  content: '';
  display: block;
  position: absolute;
  width: 0%;
  height: 2px;
  background-color: red;
  left: 0;
  bottom: 0;
  transition: 0.5s;
}

.nav_items ul li a:hover::after {
  width: 100%;
}

.nav_items ul li:first-of-type a::after {
  width: 100%;
}

/* HERO AREA START */

.hero_area {
  padding: 150px 0 100px;
  /* background-image: url('images/hero_1.jpeg'); */
  background-position: center center;
  background-size: cover;
}

.hero_inner {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;
}

.hero_right img {
  width: 50%;
  border-radius: 10px;
}

.hero_right_img {
  display: flex;
  align-items: center;
  gap: 20px;
}

.hero_right_img_3 img {
  height: 200px;
  object-fit: cover;
  margin-top: 20px;
}

.hero_left h1 {
  font-family: "Saira Condensed", sans-serif;
  font-size: 72px;
  font-weight: 700;
  color: #000000;
  margin: 0;
  text-transform: uppercase;
}

.hero_left p {
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 20px;
}

.main_btn a {
  font-family: "Saira Condensed", sans-serif;
  font-size: 20px;
  font-weight: 500;
  padding: 15px 45px;
  border-radius: 7px;
  border: 1px solid #DE081C;
  display: inline-block;
  color: #000000;
  text-transform: uppercase;
  transition: 0.2s;
}

.main_btn a:hover {
  background-color: #DE081C;
  color: #ffffff;
}

/* ABOUT US AREA START */

.about_us_area {
  padding: 0px 0 0;
}

.about_inner {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0px;
}

.about_us_right img {
  width: 100%;
  border-radius: 0px;
  height: 430px;
  object-fit: cover;
}

.about_us_left {
  padding: 20px;

}

.about_us_left h2 {
  font-family: "Saira Condensed", sans-serif;
  font-size: 46px;
  font-weight: 700;
  color: #000000;
  margin: 0 0 20px;
}

.about_us_left p {
  line-height: 24px;
  margin-bottom: 30px;
}

/* EVENTS AREA START */

.events_area {
  padding: 80px 0;
  overflow: hidden;
}

.events_title {
  text-align: center;
  max-width: 72%;
  margin: 0 auto;
}

.events_title h2 {
  font-family: "Saira Condensed", sans-serif;
  font-size: 52px;
  font-weight: 700;
  color: #000000;
  margin: 0 0 20px;
  text-transform: capitalize;
}

.events_title p {
  line-height: 24px;
  margin-bottom: 30px;
}

.events_cnt {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}

.event_img img {
  border-radius: 10px;
  width: 100%;
}

.events_item {
  position: relative;
}

.event_text {
  position: absolute;
  left: 20px;
  width: 100%;
  bottom: 20px;
}

.event_text h3 {
  font-size: 25px;
  font-weight: 700;
  color: #ffffff;
  margin: 0 0 20px;
}

.event_text a {
  color: #fff;
}

/* REVIWE AREA START  */

.reviwe_area {
  padding: 0 0 80px;
  overflow: hidden;
}

.reviwe_wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 50px;
  padding-top: 60px;
}

.reviwe_cnt {
  text-align: center;
}

.r_img img {
  width: 100px;
  border-radius: 50%;
}

.r_text h3 {
  font-family: "Saira Condensed", sans-serif;
  font-weight: 700;
  font-size: 26px;
  color: #000000;
  text-transform: capitalize;
  margin: 10px 0;
}

.r_text p {
  line-height: 26px;
  margin: 10px 0 0;
}

/* FAQ AREA START */

.faq_area {
  padding: 60px 0;
  overflow: hidden;
}

.faq-container {
  max-width: 850px;
  margin: 30px auto 0;
}

.faq_title {
  text-align: center;
}

.faq_title h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 42px;
  font-weight: 700;
  color: #171717;
  margin: 0 0 20px;
}

.faq-item {
  margin-bottom: 10px;
}

.faq-item details {
  cursor: pointer;
  padding: 10px;
  position: relative;
}

.faq-item summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  color: #171717;
}

.faq-item summary::after {
  content: '\f107';
  /* Down arrow */
  font-family: 'font awesome 5 free';
  font-size: 18px;
  color: #000000;
  margin-left: 10px;
  transition: transform 0.3s ease;
}

.faq-item[open] summary::after {
  transform: rotate(90deg);
  /* Rotates the arrow when open */
}

.faq-item p {
  padding: 10px 0;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 400;
  color: #000000;
  line-height: 26px;
  background-color: #ffffff;
  padding: 20px;
}

/* FOOTER AREA START  */

.footer_area {
  background-color: #000000;
  padding: 30px 0;
  overflow: hidden;
}

.f_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.f_logo img {
  width: 120px;
}

.f_nav ul {
  display: flex;
  align-items: center;
}

.f_nav ul li {
  padding-left: 50px;
}

.f_nav ul li a {
  font-family: "Saira Condensed", sans-serif;
  font-weight: 500;
  color: #ffffff;
  font-size: 22px;
  text-transform: uppercase;
  position: relative;
  transition: 0.2s;
}

.f_nav ul li a:hover {
  color: #DE081C;
}



























@media screen and (min-width: 1200px) and (max-width: 1400px) {}

/* FOR-LG-START */
@media screen and (min-width: 992px) and (max-width: 1199px) {


  .hero_left h1 {
    font-size: 62px;
  }




}

/*FOR-TABLATE-START*/
@media screen and (min-width: 768px) and (max-width: 991px) {

  .sidebar-menu {
    display: block;
  }

  .hero_left h1 {
    font-size: 48px;
  }

  .hero_area {
    padding: 100px 0;
  }

  .event_text h3 {
    font-size: 20px;
    padding-right: 20px;
  }






}

/*FOR-MOBIL-START*/
@media screen and (max-width: 767px) {

  .sidebar-menu {
    display: block;
  }

  .menu-btn {
    display: block;
  }

  .nav_items {
    display: none;
  }

  .logo img {
    width: 100px;
  }

  .hero_inner {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }

  .hero_left {
    order: 2;
    text-align: center;
  }

  .hero_area {
    padding: 50px 0;
  }

  .hero_left h1 {
    font-size: 36px;
  }

  .hero_left p {
    font-size: 15px;
    margin: 10px 0 20px;
  }

  .main_btn a {
    font-size: 18px;
  }

  .about_inner {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0px;
  }

  .about_us_right img {
    border-radius: 10px;
  }

  .about_us_left {
    padding: 20px 0;
    order: 2;
  }

  .about_us_left h2 {
    font-size: 30px;
  }

  .events_title {
    max-width: 100%;
  }

  .events_title h2 {
    font-size: 27px;
  }

  .events_area {
    padding: 25px 0;
  }

  .events_cnt {
    grid-template-columns: repeat(1, 1fr);
  }

  .reviwe_wrapper {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 40px;
    padding-top: 0px;
  }

  .faq_area {
    padding: 0 0 30px;
  }

  .faq-item p {
    font-size: 16px;
  }

  .f_inner {
    display: block;
    text-align: center;
  }

  .f_nav ul li {
    padding-left: 0;
    padding: 0 5px;
  }

  .f_nav ul li a {
    font-size: 14px;
  }

  .f_nav ul {
    justify-content: center;
  }

  .event_text h3 {
    font-size: 22px;
  }

  .faq-item summary {
    font-size: 16px;
  }


}











/* Add this to your CSS file */
.spinner {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.loading-circle {
  box-sizing: border-box;
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #000;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}



